import { useEffect } from 'react';
import type { NextPage } from 'next';
import NextLink from 'next/link';
import Head from 'next/head';
import {
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { gtm } from '@/lib/gtm/gtm';

const NotFound: NextPage = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Head>
        <title>Error: Not Found | RipeMetrics</title>
      </Head>
      <div className="flex h-screen min-h-full flex-col bg-white pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-6 lg:px-8">
          <div className="flex shrink-0 justify-center">
            <NextLink
              href="/"
              passHref
            >
              <span className="inline-flex">
                <span className="sr-only">RipeMetrics</span>
                <img
                  className="h-12 w-auto"
                  src="/apple-touch-icon.png"
                  alt=""
                />
              </span>
            </NextLink>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-orange-600">404</p>
              <h1 className="mt-2 font-proximaSemiBold text-4xl tracking-tight text-gray-900 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <NextLink
                  href="/"
                  passHref
                >
                  <span className="text-base font-medium text-orange-600 hover:text-orange-500">
                    <span aria-hidden="true"> &larr;</span> {'  '}Go back home
                  </span>
                </NextLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default NotFound;
